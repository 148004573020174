<template>
  <v-container class="down-top-padding" fluid>

    <BaseBreadcrumb :breadcrumbs="breadcrumbs"  :title="title"></BaseBreadcrumb>

    <v-row>

     <v-col cols="12">
       <v-card class="card">
           <v-btn
               color="warning"
               depressed
               @click="test_connection">

             TEST CONNECTION
           </v-btn>
       </v-card>
     </v-col>

    </v-row>

    <v-card class="dx-card">



      <v-col id="formContainer" cols="12">

        <v-form @submit.prevent>

          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="minsan"
                  label="Inserire un Codice Prodotto"
              ></v-text-field>

              <v-btn
                  x-large
                  color="primary"
                  @click="search_commerce">
                Avvia Ricerca
              </v-btn>

            </v-col>

            <v-col cols="12" v-if="to_create">
              <p>
                <b style="color: red">
                  Il prodotto ricercato non è ancora presente in Commercio vorresti inserirlo?
                </b>
              </p>

              <v-btn
                  color="success"
                  depressed
                  @click="create_product">

                CREA PRODOTTO
              </v-btn>
            </v-col>


          </v-row>

        </v-form>

      </v-col>
    </v-card>

  </v-container>
</template>


<script>
import {db} from "@/firebase";
import axios from "axios";
import {auth} from "@/firebase";

export default {
  name: 'SingleProductInput',

  data: () => ({
    title: '',
    to_create: false,
    product_name: '',
    image_path: '',
    http_image_path: '',
    https_image_path: '',
    pharmacy: null,
    pharmacy_id: '',
    product_id: '',
    amount: '',
    full_description: '',
    list_price: '',
    price_list: '',
    main_pair: {
      detailed: {}
    },
    category_ids: [],
    minsan: '',
    price: '',
    product: '',
    product_code: '',
    promo_category: '',
    status: '',
    taxes: '',
    parafarmaco: '',
    selected_category_id: '',
    current_product: {},
    page: {
      title: 'Ricerca per codice prodotto ( MINSAN || EAN )'
    },

    breadcrumbs: [
      {
        text: "",
        disabled: true
      }
    ],

  }),

  methods: {

    load_user() {
      let _uid = auth.currentUser.uid;
      db.collection('users')
          .doc(_uid)
          .get()
          .then(result => {
            console.log('L  ===', result.data());
            this.pharmacy_id = result.data().pharmacy_id;
            this.load_pharmacy()
      }).catch(error => {
        console.log('L  ===', error.toLocaleString());
      })
    },

    load_pharmacy() {

      db.collection('farmacie')
          .doc(this.pharmacy_id)
          .get()
          .then(snapshot => {

            this.pharmacy = snapshot.data();
            this.title = this.pharmacy.pharmacy_name;
            console.log(this.pharmacy);
          }).catch(error => {
        this.$Simplert.open(this.error_string(error.toLocaleString()));
      })
    },


    get_configuration_with(path) {
      return {
        method: 'get',
        url: this.$BASEURL + path,
        headers: {
          'Content-Type': 'application/json'
        }
      }
    },
    error_string(error) {
      return {
        isShown: true,
        title: 'Attenzione',
        message: error,
        type: 'error',
      }
    },
    async test_connection() {

      let path = 'check_status';

      const config = {
        method: 'post',
        url: this.$BASEURL + path,
        headers: {
          'Content-Type': 'application/json'
        },
        data : {
          pharmacy: this.pharmacy,
        }
      };


      await axios(config)
          .then(response => {

            if (response.data.status === 404) {
              this.$Simplert.open(this.error_string('error in'));
            }

            if (response.data) {
              console.log('L  ===', response.data);
              this.$Simplert.open({
                message: response.data.message
              });
            }
          }).catch(error => {
            this.$Simplert.open(this.error_string(error.toLocaleString()));
          })

    },
    async create_product() {

      // search_commerce/:minsan/:pharmacyId/:prefisso
      // let _id = 'LF8F3IeUG2VwQXssk3wbKdn1Ffb2';
      let path = 'farmania_product/' + this.minsan;

      const config = this.get_configuration_with(path);

      await axios(config)
          .then(response => {

            if (response.data.status === 404) {
              this.$Simplert.open(this.error_string('Il codice inserito non e stato trovato'));
            }

            if (response.data.result) {

              let _product =  response.data.result;
              console.log(_product)
              delete _product['product_code'];

              this.$router.push({
                name: 'Product_Detail', params: {
                  id: this.pharmacy.id,
                  minsan: this.minsan,
                  data: _product,
                  pharmacy: this.pharmacy,
                }
              })

            }
          }).catch(error => {
            this.$Simplert.open(this.error_string(error.toLocaleString()));
          })

    },
    async search_commerce() {

      if (this.minsan.length === 0) {
        this.$Simplert.open(this.error_string('Il campo non puo essere vuoto'));
        return
      }

      if (this.pharmacy.id === undefined) {
        this.$Simplert.open(this.error_string('System Error - si prega di riprovare'));
        return
      }

      let _prefisso = this.pharmacy.prefisso;
      let path = 'search_commerce/' + this.minsan + '/' + this.pharmacy.id + '/' + _prefisso;
      const config = this.get_configuration_with(path);

      await axios(config)
          .then(response => {

            if (response.data.status === 404) {
              this.to_create = true;
              return
            }

            if (response.data.result) {
              // console.log(response.data.result);
              this.$router.push({
                name: 'Product_Detail', params: {
                  id: this.pharmacy.id,
                  minsan: this.minsan,
                  data: response.data.result,
                  pharmacy: this.pharmacy,
                }
              })
            } else {
              this.to_create = true;
            }
          }).catch(error => {
            this.$Simplert.open(this.error_string(error.toLocaleString()));
          })

    }
  },
  mounted() {
    this.load_user()
  },

}
</script>

<style lang="scss" scoped>

#formContainer {
  background-color: white;
  margin: 0px;
}

#image {
  background-color: rgb(169, 169, 169);
}

#toolbar {
  height: 80px !important;
}

#toolbarTitle {
  height: 100px !important;
}


</style>>

